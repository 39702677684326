






import Component from 'vue-class-component';
import Vue from 'vue';

@Component({
  metaInfo: {
    title: 'My applications'
  },
})
export default class Applications extends Vue {
}
